/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-11-22 17:01:21
 * @LastEditors: sj
 * @LastEditTime: 2022-04-14 13:17:16
 */
import { getToken, setToken, removeToken } from "@/utils/auth"
import { login, ssoLogin, getUserInfo } from "@/api/auth/auth"

export default {
    namespaced: true,
    state: {
        userInfo: [],
        token: getToken(),
        user: "",
        shopId: 0,
        shopInfo: {},
        shopNum: 0,
        keyword: "",
      shopDetail: {},
      activeName: 2 //首页激活 的nav
    },
    mutations: {
        SET_USERINFO(state, data) {
            state.userInfo = data
        },
        SET_TOKEN(state, data) {
            state.token = data
        },
        SET_USER(state, data) {
            state.user = data
        },
        SET_SHOPINFO(state, data) {
            state.shopInfo = data
        },
        SET_SHOPNUM(state, data) {
            state.shopNum = data
        },
      SET_SHOPID (state, data) {
        state.shopId = data
      },
      SET_KEYWORD (state, data) {
        state.keyword = data
      },
      SET_SHOPDETAIL (state, data) {
        state.shopDetail = data
      },
      SET_ACTIVITYNAME (state, data) {
        state.activeName = data
      }
    },
    actions: {
        logout({ commit }) {
            commit('SET_USERINFO', [])
            commit('SET_TOKEN', '')
            commit('SET_USER', '')
        commit("SET_SHOPINFO", {})
            localStorage.removeItem('userInfo')
            localStorage.removeItem('shopInfo')
            removeToken('Mall-auth')
        },
        setUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
                login({ ...payload }).then(response => {
                    commit("SET_TOKEN", response.accessToken)
                    setToken(response.accessToken)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setAccessToken({ commit }, payload) {
            commit("SET_TOKEN", payload)
            setToken(payload)
        },
        setSsoLogin({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ssoLogin({ ...payload }).then(response => {
                    commit("SET_TOKEN", response.accessToken)
                    setToken(response.accessToken)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(response => {
                    localStorage.setItem("userInfo", JSON.stringify(response));
                    commit("SET_USERINFO", response)
                    commit("SET_USER", response.nickName)
                    commit("SET_SHOPNUM", response.shopCartItemCount)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setShopInfo({ commit }, payload) {
            commit('SET_SHOPINFO', payload)
        },
        setShopNum({ commit }, payload) {
            commit("SET_SHOPNUM", payload)
        },
        setKeyword({ commit }, payload) {
            commit("SET_KEYWORD", payload)
      },
      setShopDetail ({ commit }, payload) {
        commit("SET_SHOPDETAIL", payload)
      },
      setShopId ({ commit }, payload) {
        commit("SET_SHOPID", payload)
      },
      setActivityName ({ commit }, payload) {
        commit("SET_ACTIVITYNAME", payload)
        }
    }

}