<template>
  <div id="app" v-title data-title="麟康商城">
    <router-view />
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/getQuery"
export default {
  name: "App",
  created() {
    if (getUrlKey("accessToken")) {
      this.$store
        .dispatch("user/setAccessToken", getUrlKey("accessToken"))
        .then(() => {
          this.$router.push("/")
        })
    }
    if (
      getUrlKey("appKey") &&
      getUrlKey("sign") &&
      getUrlKey("time") &&
      getUrlKey("tenantId") &&
      getUrlKey("phone")
    ) {
      const data = {
        appKey: getUrlKey("appKey"),
        sign: getUrlKey("sign"),
        time: getUrlKey("time"),
        tenantId: getUrlKey("tenantId"),
        phone: getUrlKey("phone"),
      }
      console.log(data)
      this.$store.dispatch("user/setSsoLogin", data).then(() => {
        this.$router.push("/")
      })
    }
  },
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  .el-input--mini .el-input__inner {
    height: 31px !important;
    line-height: 31px !important;
  }
}
// 滚动条样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background-color: #bedcfd !important;
}
</style>
