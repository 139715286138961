<template>
  <!-- <el-container> -->
  <!-- 顶部导航栏 -->
  <div class="content">
    <div class="topbar">
      <div class="nav">
        <!-- <span class="goback" v-if="pathState !== 'home'">
          <router-link to="/redirect/home">返回店铺列表</router-link>
        </span> -->
        <!-- 目前只跳到飞康店铺 -->
        <span class="goback" v-if="pathState !== 'shopHome'">
          <router-link to="/redirect/shopHome">返回首页</router-link>
        </span>
        <ul>
          <li v-if="!this.$store.getters.user">
            <el-button type="text" @click="login">登录</el-button>
          </li>
          <li v-else>
            欢迎
            <el-popover placement="top" width="180" v-model="visible">
              <p>确定退出登录吗？</p>
              <div style="text-align: right; margin: 10px 0 0">
                <el-button size="mini" type="text" @click="visible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="logout"
                  >确定</el-button
                >
              </div>
              <el-button type="text" slot="reference">{{
                this.$store.getters.user
              }}</el-button>
            </el-popover>
          </li>
          <li>
            <router-link to="/redirect/orderMain">个人中心</router-link>
          </li>
          <li>
            <router-link to="/redirect/orderMain">我的订单</router-link>
          </li>
          <router-link to="/redirect/car">
            <li class="shopCart">
              <i class="el-icon-shopping-cart-full"></i> 购物车
              <span class="num">{{ this.$store.getters.shopNum }}</span>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <!-- 顶部导航栏END -->

    <!-- 顶栏容器 -->
    <el-header
      v-if="
        pathState === 'home' ||
          pathState === 'shopHome' ||
          pathState === 'shopDetail'
      "
    >
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        active-text-color="#409eff"
        :default-active="pathState"
      >
        <div class="logo" v-if="pathState === 'home'">
          <router-link to="/">
            <img src="@/assets/imgs/logo.png" alt />
            <!-- <img src="./assets/imgs/logo.png" alt /> -->
          </router-link>
        </div>
        <div class="shoplogo" v-else @click="gotoShopHome">
          <img :src="shopInfo.shopLogo" alt />
        </div>
        <!-- <el-menu-item
          index="shopHome"
          @click="gotoShopHome"
          v-if="pathState === 'shopHome' || pathState === 'shopDetail'"
          >首页</el-menu-item
        >
        <el-menu-item
          index="shopDetail"
          @click="gotoShopDetail"
          v-if="pathState === 'shopHome' || pathState === 'shopDetail'"
          >全部商品</el-menu-item
        > -->
        <div
          class="so"
          v-if="pathState === 'shopHome' || pathState === 'shopDetail'"
        >
          <el-input placeholder="请输入搜索内容" @keyup.enter.native="gotoSearch" v-model="search">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="gotoSearch"
            ></el-button>
          </el-input>
        </div>
      </el-menu>
    </el-header>
    <!-- 顶栏容器END -->

    <!-- 主要区域容器 -->
    <el-main>
      <div class="content">
        <router-view></router-view>
        <div class="service" @click="service" v-if="isShowService">
          <svg-icon icon-class="serivePeople" class-name="serivePeople" />
        </div>
      </div>
    </el-main>
    <!-- 主要区域容器END -->

    <!-- 底栏容器 -->
    <div class="footer">
      <div class="ng-promise-box">
        <div class="ng-promise">
          <dl>
            <dt>
              <i class="el-icon-truck"></i>
            </dt>
            <dd>
              <p class="title">自有物流</p>
              <p class="intro">优质品牌100%保障</p>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="el-icon-document-checked"></i>
            </dt>
            <dd>
              <p class="title">资质资料</p>
              <p class="intro">轻松采购，便捷支付</p>
            </dd>
          </dl>
          <dl>
            <dt>
              <i class="el-icon-service"></i>
            </dt>
            <dd>
              <p class="title">客服电话</p>
              <p class="intro">
                {{
                  pathState === 'shopHome' || pathState === 'shopDetail'
                    ? shopInfo.phone
                    : '18858585850'
                }}
              </p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- 底栏容器END -->
  <!-- </el-container> -->
</template>

<script>
import { getUserCartList, getUserServiceInfo } from '@/api/shopCar/shopCar'
import { getToken } from '@/utils/auth'
import { debounce } from 'lodash'
export default {
  data() {
    return {
      search: '',
      shopInfo: {},
      visible: false,
      pathState: '',
      fishSdkReady: false, // 网易七鱼是否加载
      userServiceInfo: null,
      sdkTemplateId: 0, // 客服聊天模板
      serviceGroupID: 481584223, // 测试组客服id
      defaultImage: require('@/assets/imgs/defaultImage.png'), // 默认图片
      isShowService: false, // 是否展示客服按钮
    }
  },
  created() {
    // this.serviceQIYU()
    if (localStorage.getItem('userInfo') && getToken()) {
      this.init()
      // 如果已经登录，设置vuex登录状态
      this.$store.dispatch(
        'user/setUserInfo',
        JSON.parse(localStorage.getItem('userInfo'))
      )
    }
    if (localStorage.getItem('shopInfo')) {
      // 如果已经登录，设置vuex登录状态
      this.$store.dispatch(
        'user/setShopInfo',
        JSON.parse(localStorage.getItem('shopInfo'))
      )
    }
    if (this.$store.state.user.token && !localStorage.getItem('userInfo')) {
      // if (localStorage.getItem("Mall-auth") && !localStorage.getItem("userInfo")) {
      this.getUserinfo()
    }
  },
  watch: {
    immediate: true,
    deep: true,
    '$store.state.user.shopInfo'() {
      this.getShopInfo()
    },
    $route: 'getUrl',
    search: debounce(function() {
      this.$store.dispatch('user/setKeyword', this.search)
    }, 300),
    '$store.state.user.keyword'(v) {
      this.search =v
    },
  },
  mounted() {
    this.getUrl()
    this.getShopInfo()
    this.serviceQIYU()
  },
  methods: {
    serviceQIYU() {
      const self = this
      // 目前只对飞康 开发 测试 环境开放客服功能
      if (
        process.env.NODE_ENV === 'development' ||
        location.hostname === 'fk.store.mall.linkang-medical.com' ||
        location.hostname === 'fk.store.mall.test.hydhis.com'
      ) {
        self.isShowService = true
      } else {
        self.isShowService = false
      }

      console.log(process.env.NODE_ENV, self.isShowService)

      if (location.hostname === 'fk.store.mall.linkang-medical.com') {
        self.sdkTemplateId = 6623295
        self.serviceGroupID = 481578866
      }
     if(self.isShowService) {
        (function(w, d, n, a, j) {
        w[n] =
          w[n] ||
          function() {
            (w[n].a = w[n].a || []).push(arguments)
          }
        j = d.createElement('script')
        j.async = true
        j.src = `https://qiyukf.com/script/7ddf5022739e69242d49fd1ca71458f5.js?sdkTemplateId=${self.sdkTemplateId}`
        d.body.appendChild(j)
      })(window, document, 'ysf')
      window.ysf('onready', function() {
        self.fishSdkReady = true
      })
     }
    },

    async init() {
      const list = await getUserCartList()
      this.$store.dispatch('user/setShopNum', list.allCount)
    },
    login() {
      this.$router.push('/login')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$store.dispatch('user/setShopNum', 0)
      this.$router.push('/')
    },
    setSearch() {
      console.log(this._)
    },
    getOrderDetail() {
      this.orderId = this.$route.query.id.toString().split(',')
      // orderDeTail(this.orderId).then((data) => {
      //   this.dataForm = data.orderVOS[0];
      // });
    },
    getUrl() {
      document.body.scrollIntoView()
      if (this.$route.name === 'home') {
        this.$store.dispatch('user/setShopInfo', {})
        localStorage.removeItem('shopInfo')
      }
      this.pathState = this.$route.name
    },
    async getUserinfo() {
      await this.$store.dispatch('user/setUserInfo')
    },
    getShopInfo() {
      this.shopInfo =
        this.$store.state.user.shopInfo ||
        JSON.parse(localStorage.getItem('shopInfo'))
    },
    gotoShopHome() {
      this.$router.push({
        name: 'shopHome',
        params: { shopId: this.shopInfo.shopId },
      })
    },
    gotoShopDetail() {
      this.$router.push({
        name: 'shopDetail',
        params: { shopId: this.shopInfo.shopId },
      })
    },
    gotoSearch() {
      this.$store.dispatch('user/setKeyword',this.search)
      // this.$router.push({
      //   name: 'shopDetail',
      //   params: { shopId: this.shopInfo.shopId, keyword: this.search },
      // })
    },
    // 客服
    async service() {
      const self = this
      if (!this.$store.getters.user) {
        this.$message.error('请先登录')
        return
      }
      this.userServiceInfo = await getUserServiceInfo()
      const width = (window.screen.width - 595) / 2 // 保证窗口居中打开
      const height = (window.screen.height - 620) / 2
      const { nickName, phone, tenantName, uid } = this.userServiceInfo
      console.log('isProduction', this.isProduction)
      if (this.fishSdkReady) {
        // 判断是否处在商品详情页，在的话发送商品卡片
        if (location.href.includes('/redirect/detailPage/?')) {
          const shopDetail = this.$store.state.user.shopDetail
          window.ysf('config', {
            uid: uid,
            name: nickName,
            mobile: phone,
            data: JSON.stringify([
              { key: 'real_name', value: nickName },
              { key: 'mobile_phone', value: phone },
              // { key: 'email', value: '13800000000@163.com' },
              {
                index: 0,
                key: 'tenName',
                label: '机构名称',
                value: tenantName,
              },
            ]),
            //  staffid:this.isProduction?null: 6115395 , // 指定开发、测试用的客服ID
            groupid: self.serviceGroupID, // 客服组ID 481578866:飞康客服组  ,481584223：测试客服组
            success: function() {
              window.ysf('product', {
                show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
                title: shopDetail.name,
                desc: shopDetail.sellingPoint || '-',
                picture: shopDetail.mainImgUrl || self.defaultImage,
                note: (shopDetail.priceFee / 100).toFixed(2) + '元',
                url: location.href,
                success: function() {
                  // 成功回调
                  window.open(
                    `${window.ysf('url')}`,
                    '_blank',
                    `fullscreen=no,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no, copyhistory=no,width=595,height=620,left=${width},top=${height}`
                  )
                },
                error: function() {
                  // 错误回调
                  // handle error
                },
              })
            },
            error: function() {},
          })
        } else {
          // 不在详情页，不发送卡片
          window.ysf('config', {
            uid: uid,
            name: nickName,
            mobile: phone,
            data: JSON.stringify([
              { key: 'real_name', value: nickName },
              { key: 'mobile_phone', value: phone },
              {
                index: 0,
                key: 'tenName',
                label: '机构名称',
                value: tenantName,
              },
            ]),
            // staffid:this.isProduction?null: 6115395 , // 指定开发、测试用的客服ID
            groupid: self.serviceGroupID, // 481578866:飞康客服组  ,481584223：测试客服组
            success: function() {
              // 成功回调
              window.open(
                `${window.ysf('url')}`,
                '_blank',
                `fullscreen=no,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no, copyhistory=no,width=595,height=620,left=${width},top=${height}`
              )
            },
            error: function() {},
          })
        }
      } else {
        this.$message.error('客服系统更新中，暂不可用。')
      }
    },
  },
}
</script>

<style lang="less" scoped>
/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}

#app .el-container {
  /* height: 100%; */
  flex-direction: column;
}

#app .el-header {
  width: 100%;
  background: #fff;
  height: 80px !important;
  padding-top: 10px !important;
}
#app .el-main {
  min-height: 1000px;
  padding: 0;
  background: #f4f4f4;
  width: 100%;
  /* overflow: hidden; */
}
#app .el-main .content {
  min-height: 1000px;
  margin: 0 auto;
}
.service {
  position: fixed;
  top: 50%;
  right: 5px;
  width: 60px;
  height: 60px;
  transform: translateY(10px);
  cursor: pointer;
  // background-color: aqua;
  .serivePeople {
    width: 100%;
    height: 100%;
  }
}
#app .el-footer {
  height: 90px;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}

.el-button--mini,
.el-button--mini.is-round {
  padding: 7px 15px !important;
}
/* 全局CSS END */

/* 顶部导航栏CSS */
.topbar {
  height: 40px;
  background-color: #3d3d3d;
}
.topbar .nav {
  width: 1225px;
  margin: 0 auto;
}
.topbar .nav ul {
  float: right;
}
.topbar .nav li {
  float: left;
  height: 40px;
  color: #b0b0b0;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;
}
.topbar .nav .sep {
  color: #b0b0b0;
  font-size: 12px;
  margin: 0 5px;
}
.topbar .nav li .el-button {
  color: #b0b0b0;
}
.topbar .nav .el-button:hover {
  color: #fff;
}
.topbar .nav li a {
  color: #b0b0b0;
}
.topbar .nav a:hover {
  color: #fff;
}
.topbar .nav .shopCart {
  width: 120px;
  background: #424242;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topbar .nav .shopCart:hover {
  background: #fff;
}
.topbar .nav .shopCart:hover {
  color: #ff6700;
}
.topbar .nav .shopCart-full {
  width: 120px;
  background: #ff6700;
}
.topbar .nav .shopCart-full a {
  color: white;
}
.el-menu-demo {
  border-bottom: none !important;
}
.goback a {
  color: #ccc;
  font-size: 14px;
  line-height: 40px;
}
/* 顶部导航栏CSS END */

/* 顶栏容器CSS */
.el-header .el-menu {
  width: 1225px;
  margin: 0 auto;
}
.el-header .el-menu .el-menu-item {
  padding: 0 20px;
}
.el-header .el-button {
  padding: 10px !important;
}
.el-header .logo {
  height: 60px;
  width: 190px;
  float: left;
}
.el-header .logo img {
  height: 60px;
}
.el-header .shoplogo {
  height: 60px;
  width: 189px;
  float: left;
  margin-right: 100px;
}
.el-header .shoplogo img {
  height: 60px;
}
.el-header .so {
  margin-top: 10px;
  width: 300px;
  float: right;
}
.el-main /deep/ .el-input-group__append {
  text-align: center !important;
}
/* 顶栏容器CSS END */

/* 底栏容器CSS */
.footer {
  width: 100%;
  background: #2f2f2f;
}
.footer .ng-promise-box {
  border-bottom: 1px solid #3d3d3d;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
}
.footer .ng-promise-box {
  margin: 0 auto;
  border-bottom: 1px solid #3d3d3d;
}
.footer .ng-promise-box .ng-promise {
  display: flex;
  width: 1225px;
  margin: 0 auto;
  align-content: center;
  padding-top: 20px;
  justify-content: space-between;
}
.footer .ng-promise-box .ng-promise dt,
dd {
  float: left;
}
.footer .ng-promise-box .ng-promise dt {
  line-height: 70px;
  margin-right: 10px;
}
.footer .ng-promise-box .ng-promise dt i {
  font-size: 36px;
}
.el-icon-shopping-cart-full:before {
  font-size: 17px;
  margin-right: 5px;
}
/* 底栏容器CSS END */
/* car_herder */

::-webkit-scrollbar {
  width: 5px; /*对垂直流动条有效*/
  height: 5px; /*对水平流动条有效*/
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #ff6700;
  background: #ff6700;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}

.title {
  font-size: 16px;
}
.intro {
  opacity: 0.7;
}
</style>
