/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-11-22 17:01:21
 * @LastEditors: sj
 * @LastEditTime: 2022-04-14 13:08:31
 */
const getters = {
    user: state => state.user.user,
    token: state => state.user.token,
    shopId: state => state.user.shopId,
  shopNum: state => state.user.shopNum,
  shopDetail: state => state.user.shopDetail,
  activeName: state => state.user.activeName
}
export default getters
