/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-11-22 17:01:21
 * @LastEditors: sj
 * @LastEditTime: 2022-02-22 09:53:41
 */
import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['Mall-auth'] = getToken()
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code === 200 || res.code === "00000") {
            return res.data
        }
    if (res.code === 400 || res.code === 'A00001' || res.code === '10001' || res.code === 'A00003' || res.code === 'A00005') {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 1.5 * 1000
            })
            return Promise.reject(res)
        }
        if (res.code === "A00004") {
            router.push('/login')
            return Promise.reject()
        }
        return Promise.reject(res)
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 1.5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
