import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import './icons' // icon
import _ from 'lodash'
Vue.use(less)
Vue.prototype._ = _
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
ElementUI.Dialog.props.lockScroll.default = false;
// ElementUI.Confirm.props.lockScroll.default = false;

// 需要登录后才能访问的路由
const permissionRouter = ['/redirect/order', '/redirect/car', '/redirect/myAddress', '/redirect/orderMain', '/redirect/orderIndex', '/redirect/collect']

Vue.use(ElementUI)

import Global from './Global'
Vue.use(Global)
// // 全局解析守卫
router.beforeResolve((to, from, next) => {
  if (permissionRouter.indexOf(to.path) < -1) {
    // console.log(this);
    if (!localStorage.getItem('userInfo')) {
      ElementUI.Message.success('请登录后再继续该操作')
      next('/login')
    }
  }
  next()
})


Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// 时间过滤器
Vue.filter('dataFormat', (dataStr) => {
  const time = new Date(dataStr)
  function timeAppZero(str) {
    if (str < 10) {
      return "0" + str
    }
    return str
  }

  const y = time.getFullYear()
  const m = time.getMonth() + 1
  const d = time.getDate()
  const h = time.getHours()
  const mm = time.getMinutes()
  const s = time.getSeconds()

  return y + '-' + timeAppZero(m) + '-' + timeAppZero(d) + ' ' + timeAppZero(h) + ':' + timeAppZero(mm) + ':' + timeAppZero(s)
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
