exports.install = (Vue) => {
    // Vue.prototype.$target
    Vue.prototype.notifySucceed = (mes) => {
        this.$notify({
            title: '成功',
            message: mes,
            type: "success",
            offset: 100
        })
    }
    Vue.prototype.notifyError = (mes) => {
        this.$notify({
            title: '失败',
            message: mes,
            type: 'error',
            offset: 100
        })
    }
}
