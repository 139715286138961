import request from '@/utils/request'

export function login(params) {
    return request({
        url: "/blade-user/auth/mall/token",
        method: "post",
        params
    })
}

export function ssoLogin(params) {
    return request({
        url: '/mall-user/pc/user/auth/sso',
        method: "get",
        params
    })
}

export function getUserInfo() {
    return request({
        url: "/mall-user/pc/user/simple_info",
        method: "get"
    })
}

export function getQuery(params) {
    return request({
        url: '/blade-user/auth/queryByPhone',
        method: "get",
        params
    })
}

export function logout() {
    return request({
        url: '/mall-auth/login_out',
        method: 'post'
    })
}
