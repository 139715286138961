import Vue from 'vue'
import Router from "vue-router"
import Layout from '@/layout'

Vue.use(Router)

const routes = [{
  path: '/',
  redirect: '/redirect'
},
{
  path: '/redirect',
  component: Layout,
  children: [
    {
      path: '/redirect',
      // redirect: '/redirect/home'
      // 目前只跳到飞康店铺
      redirect: '/redirect/shopHome'
    },
    {
      path: '/redirect/home',
      name: 'home',
      component: () => import('@/views/index/home'),
      meta: {
        title: '麟康商城-首页'
      }
    },
    {
      path: '/redirect/shopHome',
      name: 'shopHome',
      component: () => import('@/views/index/shopHome'),
      meta: {
        title: '麟康商城-店铺首页'
      }
    },
    {
      path: '/redirect/shopDetail',
      name: 'shopDetail',
      component: () => import('@/views/index/shopDetail'),
      meta: {
        title: '麟康商城-全部商品'
      }
    },
    {
      path: '/redirect/order',
      name: 'order',
      component: () => import('@/views/shopCar/sureOrder'),
      meta: {
        title: '麟康商城-确认订单'
      }

    }, {
      path: '/redirect/detailPage',
      name: 'detailPage',
      component: () => import('@/views/detailPage'),
      meta: {
        title: '麟康商城-商品详情'
      }
    },
    {
      path: '/redirect/car',
      name: 'car',
      component: () => import('@/views/shopCar'),
      meta: {
        title: '麟康商城-我的购物车'
      }
    },
    {
      path: '/redirect/orderMain',
      name: 'orderMain',
      component: () => import('@/views/order/main.vue'),
      meta: {
        title: '麟康商城-我的订单'
      }
    },
    {
      path: '/redirect/orderDetail',
      name: 'orderDetail',
      component: () => import('@/views/order/order/orderDetail.vue'),
      meta: {
        title: '麟康商城-订单详情'
      }
    },
    {
      path: '/redirect/payment',
      name: 'payment',
      component: () => import('@/views/order/order/payment.vue'),
      meta: {
        title: '麟康商城-支付订单'
      }
    },
    {
      path: '/redirect/myAddress',
      name: 'myAddress',
      component: () => import('@/views/order/Address/myAddress.vue'),
      meta: {
        title: '麟康商城-收货地址'
      }

    }
  ]
},
{
  path: '/login',
  name: 'login',
  component: () => import('../views/common/login.vue')
},
{
  path: '/error',
  name: 'error',
  component: () => import('../components/Error.vue')
},
]

const router = new Router({
  routes
})
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })


export default router