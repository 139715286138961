/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-11-22 17:01:21
 * @LastEditors: sj
 * @LastEditTime: 2021-12-21 13:43:53
 */
import request from '@/utils/request'

// 获取用户购物车商品列表
export function getUserCartList() {
  return request({
      url: '/mall-product/pc/shop_cart/info',
  })
}

// 删除购物车商品
export function deleteProduct(data) {
  return request({
      url: `/mall-product/pc/shop_cart/delete_item`,
      method: 'delete',
      data
  })
}

// 修改购物车数量
export function editProduct(data) {
  return request({
      url: '/mall-product/pc/shop_cart/change_item',
      method:'post',
      data
  })
}

// 获取用户信息传递给客服后台
export function getUserServiceInfo () {
  return request({
    url: '/mall-user/pc/user/imUserInfo',
  })
}